import React from "react"
import { Container } from 'react-bootstrap'
import { BrowserRouter as Router, Route, Switch } from "react-router-dom"
import Home from './Home/Home';
import './App.css';

function App() {
  return (
    <Container className="background" style={{ maxWidth: "100%", paddingLeft: '0px', paddingRight: '0px' }}>
            <Router>
                  <Switch>
                  <Route path="/" component={Home} />
                  </Switch>
            </Router>
      </Container>
  );
}

export default App;
