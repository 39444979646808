import React, { useState } from 'react'
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import './Home.css'

export default function Home() {

    return(
        <>
            <Header></Header>
            <header class="bg-light py-5 welcome-banner">
                <div class="container px-5">
                    <div class="row gx-5 align-items-center justify-content-center">
                        <div class="col-lg-8 col-xl-7 col-xxl-6">
                            <div class="my-5 text-center text-xl-start">
                                <h1 class="display-5 fw-bolder mb-2 welcome-header">Welcome to KSPG India</h1>
                                <p class="welcome-message lead fw-normal text-black-50 mb-4">We stand <span class="green-text">together</span> with a vision to <span class="green-text">inoculate better Social Relatioships</span> within Family as well as the society by spreading of various messages in relation thereto and provide better <span class="green-text">Health-care facilities</span> by using traditional <span class="green-text">Herbal medications</span>.</p>
                                <div class="d-grid gap-3 d-sm-flex justify-content-sm-center justify-content-xl-start">
                                    <a class="btn btn-success btn-lg px-4 me-sm-3" href="#features">Join the Voyage</a>
                                    <a class="btn btn-outline-dark btn-lg px-4" href="#!">About Us</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-5 col-xxl-6 d-none d-xl-block text-center"><img class="img-fluid rounded-3 my-5" src="india-background.jpg" alt="welcome to kspg india" /></div>
                    </div>
                </div>
            </header>
            <section class="py-5" id="features">
                <div class="container px-5 my-5">
                    <div class="row gx-5">
                        <div class="col-lg-4 mb-5 mb-lg-0"><h2 class="fw-bolder mb-0">Aim and Objectives of the Trust</h2></div>
                        <div class="col-lg-8">
                            <div class="row gx-5 row-cols-1 row-cols-md-2">
                                <div class="col mb-5 h-100">
                                    <div class="feature bg-primary bg-gradient text-white rounded-3 mb-3"><i class="bi bi-collection"></i></div>
                                    <h2 class="h5">Encourage Traditional Craftperson & Artists</h2>
                                    <p class="mb-0 objective-text">Provide a platform that shall encourage Traditional Craftsperson and Artists to showcase their talent and help revive the over-looked culture.</p>
                                </div>
                                <div class="col mb-5 h-100">
                                    <div class="feature bg-primary bg-gradient text-white rounded-3 mb-3"><i class="bi bi-building"></i></div>
                                    <h2 class="h5">Promote Traditional Herbal Medications</h2>
                                    <p class="mb-0 objective-text">Provide better Health-care facilities by using Traditional Herbal medications. Support Herbal medicine manufacturing unit development and functioning.</p>
                                </div>
                                <div class="col mb-5 mb-md-0 h-100">
                                    <div class="feature bg-primary bg-gradient text-white rounded-3 mb-3"><i class="bi bi-toggles2"></i></div>
                                    <h2 class="h5">Conduct Socio-Cultural Activities</h2>
                                    <p class="mb-0 objective-text">Organize various events at different places (both urban and rural) especially for children and women with an aim to restructure the forgotten traditional culture of the lands.</p>
                                </div>
                                <div class="col h-100">
                                    <div class="feature bg-primary bg-gradient text-white rounded-3 mb-3"><i class="bi bi-toggles2"></i></div>
                                    <h2 class="h5">Initiate Knowledge Exchange Process</h2>
                                    <p class="mb-0 objective-text">Act as a medium concerning the knowledge exchange process for the benefit of people globally by conducting seminars and trainings. Create job opportunities for individuals and encourage women empowerment across regions.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <div class="py-5 bg-light">
                <div class="container px-5 my-5">
                    <div class="row gx-5 justify-content-center">
                        <div class="col-lg-10 col-xl-7">
                            <div class="text-center">
                                <div class="fs-4 mb-4 fst-italic">"You fight for what’s right, you set that vision of where we could be — and then you go after it. Doing what's right doesn't necessarily need high strength & capital, it's the small steps that you take to motivate others for being a part. One man alone couldn't have helped get Independence, but he can alone be enough to make an impact that matters and bring others Together."</div>
                                <div class="d-flex align-items-center justify-content-center">
                                    <img class="rounded-circle me-3" src="sarita_panda_40_40.svg" alt="..." />
                                    <div class="fw-bold">
                                        Sarita Panda
                                        <span class="fw-bold text-primary mx-1">/</span>
                                        Founder Trustee
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <section class="py-5">
            <div class="container px-5 my-5">
                <div class="row gx-5 justify-content-center">
                    <div class="col-lg-8 col-xl-6">
                        <div class="text-center">
                            <h2 class="fw-bolder">From your Motivation</h2>
                            <p class="lead fw-normal text-muted mb-5">It's the support and motivation of you all that help us strive ahead with the vision, and make an Impact Together!</p>
                        </div>
                    </div>
                </div>
                <div class="row gx-5">
                    <div class="col-lg-4 mb-5">
                        <div class="card h-100 shadow border-0">
                            <img class="card-img-top" src="kspgpublication.svg" alt="..." />
                            <div class="card-body p-4">
                                <div class="badge bg-primary bg-gradient rounded-pill mb-2">Knowledge Sharing</div>
                                <a class="text-decoration-none link-dark stretched-link" href="#!"><h5 class="card-title mb-3">KSPG Publication</h5></a>
                                <p class="card-text mb-0 objective-text">KSPG Publication provides a medium concerning the knowledge exchange process for the benefit of people globally. In addition, we support research activities and recognize outstanding research contributions in Natural Medicine, Science, and Technology.</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 mb-5">
                        <div class="card h-100 shadow border-0">
                            <img class="card-img-top" src="kshomemadeproducts.svg" alt="..." />
                            <div class="card-body p-4">
                                <div class="badge bg-primary bg-gradient rounded-pill mb-2">Herbal Medication</div>
                                <a class="text-decoration-none link-dark stretched-link" href="#!"><h5 class="card-title mb-3">K S Home-made Products</h5></a>
                                <p class="card-text mb-0 objective-text">KS Home-made products is a manufacturing unit that provides better Healthcare facilities by using traditional Herbal medications. We aim to set up new industries, create job opportunities for individuals, and encourage women empowerment across regions to manufacture herbal medicine and essential products.</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 mb-5">
                        <div class="card h-100 shadow border-0">
                            <img class="card-img-top" src="knowledgeforreal.svg" alt="..." />
                            <div class="card-body p-4">
                                <div class="badge bg-primary bg-gradient rounded-pill mb-2">Open Webinars</div>
                                <a class="text-decoration-none link-dark stretched-link" href="#!"><h5 class="card-title mb-3">#KnowledgeForReal Initiative</h5></a>
                                <p class="card-text mb-0 objective-text">#KnowledgeForReal is an initiative by KSPG India that conducts online Webinars during Covid, ensuring the safety of all participants and provides a platform to gain knowledge and learn from experiences of others involving topics of general interest.</p>
                            </div>
                        </div>
                    </div>
                </div>
                
                <aside class="bg-primary bg-gradient rounded-3 p-4 p-sm-5 mt-5">
                    <div class="d-flex align-items-center justify-content-between flex-column flex-xl-row text-center text-xl-start">
                        <div class="mb-4 mb-xl-0">
                            <div class="fs-3 fw-bold text-white">New Updates, delivered to your Inbox</div>
                            <div class="text-white-50">Sign up for our newsletter for the latest updates.</div>
                        </div>
                        <div class="ms-xl-4">
                            <div class="input-group mb-2">
                                <input class="form-control" type="text" placeholder="Email address..." aria-label="Email address..." aria-describedby="button-newsletter" />
                                <button class="btn btn-success btn-outline-light" id="button-newsletter" type="button">Sign up</button>
                            </div>
                            <div class="small text-white-50">We care about privacy, and will never share your data.</div>
                        </div>
                    </div>
                </aside>
            </div>
        </section>
        <Footer></Footer>
        </>
    )
}