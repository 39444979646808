import React from 'react';
import { Button, Nav, Navbar, Container, NavDropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import './Header.css'

export default function Header() {

    return(
        <>
            <Container class="header" style={{ maxWidth: "100%", paddingLeft: '0px', paddingRight: '0px' }}>
                <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
                    <Container>
                    <Link class="link" to="/"><img className="logo"  width={"80px"} src="./kspg_logo_600_600.svg" alt="KSPG India"></img></Link>
                    <Navbar.Brand>Kushal Swadesh Pratistha Group</Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="me-auto">
                        <Link class="link" to="/"><Nav.Link href="/trust-details">Home</Nav.Link></Link>
                        <Link class="link" to="/about-us"><Nav.Link href="/trust-details">Who are We</Nav.Link></Link>

                        <NavDropdown title="Creating Impact" id="collasible-nav-dropdown">
                        <Link class="link" to="/trustees"><NavDropdown.Item href="/">Trustees</NavDropdown.Item></Link>
                        <Link class="link" to="/board-of-advisors"><NavDropdown.Item href="/">Board of Advisors</NavDropdown.Item></Link>
                        <Link class="link" to="/privacy-policy"><NavDropdown.Item href="/">Privacy Policy</NavDropdown.Item></Link>
                        <NavDropdown.Divider />
                        <NavDropdown.Item  disabled>Media Gallery</NavDropdown.Item>
                        <Link class="link" to="/photos"><NavDropdown.Item href="/">Photo Gallery</NavDropdown.Item></Link>
                        <Link class="link" to="/videos"><NavDropdown.Item href="/">Video Gallery</NavDropdown.Item></Link>
                        </NavDropdown>

                        <NavDropdown title="How can you Help" id="collasible-nav-dropdown">
                        <Link class="link" to="/donate-now"><NavDropdown.Item href="/">Donate Now</NavDropdown.Item></Link>
                        </NavDropdown>

                        <Link class="link" to="/contact-us"><Nav.Link href="/contact-us">Contact Us</Nav.Link></Link>
                    </Nav>
                    <Nav>
                        <a href="https://accounts.kspgindia.com"><Button variant="success">Login <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-box-arrow-in-right" viewBox="0 0 16 16">
                        <path fill-rule="evenodd" d="M6 3.5a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-2a.5.5 0 0 0-1 0v2A1.5 1.5 0 0 0 6.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-8A1.5 1.5 0 0 0 5 3.5v2a.5.5 0 0 0 1 0v-2z"/>
                        <path fill-rule="evenodd" d="M11.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H1.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3z"/>
                      </svg></Button>{' '}</a>
                    </Nav>
                    </Navbar.Collapse>
                    </Container>
                </Navbar>
            </Container>
            
        </>
    )
}